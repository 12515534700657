import {AbstractComponentController} from "../AbstractComponentController";
import {gsap} from "gsap";
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";

/******************************************************************
 * HeaderNavigationController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class HeaderNavigationController extends AbstractComponentController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _navAnchors: HTMLElement[];
    private _hamburgerIcon: HTMLElement;
    private _closeIcon: HTMLElement;
    private _menuState = new StateValue<"show" | "hide">("hide");
    private _navigation: HTMLElement;
    private _menuUI: HTMLElement;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement);
        this.initElements();
        this.initListeners();
        this.updateMenu();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.updateMenu();
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._navAnchors = this.getElements("a");
        this._navigation = this.getElement(".header-navigation");
        this._hamburgerIcon = this.getElement(".header-menu-ui-hamburger");
        this._menuUI = this.getElement(".header-menu-ui");
        this._closeIcon = this.getElement(".header-menu-ui-close");
    }

    private initListeners() {
        this._menuState.onChangeSignal.add(() => this.onMenuStateChanged());
        this._hamburgerIcon.addEventListener("click", () => this.onHamburgerClicked());
        this._closeIcon.addEventListener("click", () => this.onCloseClicked());
        this._navAnchors.forEach((anchor: HTMLAnchorElement) => {
            anchor.addEventListener("click", (e) => this.onAnchorClicked(e, anchor));
        })
    }

    private scrollTo(href: string) {
        gsap.to(window, {
            duration: 1,
            scrollTo: {
                offsetY: this.getElementMargin(href),
                y: href
            },
            ease: "power4.inOut"
        });
    }

    private getElementMargin(href: string): number {
        const sectionElement = document.getElementById(href.split("#")[1]);
        if (!sectionElement) return 0;
        return parseInt(window.getComputedStyle(sectionElement).marginTop);
    }

    private updateMenu() {
        this._navigation.style.display = this._menuState.isValue("show") || !this.isMobile() ? (this.isMobile() ? "block" : "flex") : "none";
        this._hamburgerIcon.style.display = this._menuState.isValue("show") ? "none" : "block";
        this._closeIcon.style.display = this._menuState.isValue("show") ? "block" : "none";
    }

    private isMobile(): boolean {
        return window.getComputedStyle(this._menuUI).display == "block";
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onAnchorClicked(e: MouseEvent, anchor: HTMLAnchorElement) {
        e.preventDefault();
        this._menuState.setValue("hide");
        this.scrollTo("#" + anchor.href.split("#")[1]);
    }

    private onHamburgerClicked() {
        this._menuState.setValue("show");
    }

    private onCloseClicked() {
        this._menuState.setValue("hide");
    }

    private onMenuStateChanged() {
        this.updateMenu();
    }

}
