import {UIComponent} from "@webfruits/core";

/******************************************************************
 * AbstractComponentController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class AbstractComponentController {

    /******************************************************************
     * Properties
     *****************************************************************/

    protected _root: UIComponent;
    private _latestWindowWidth = 0;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        this._root = new UIComponent(rootElement);
        this._root.onStageResizeSignal.add(() => this.onStageResized());
        this._root.onRemovedFromStageSignal.addOnce(() => this.destroy());
        requestAnimationFrame(() => this.updateStyles());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public destroy() {
        this._root.destroy();
        this._root = null;
    }

    public updateStyles() {
        // override this
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    protected getElement(selector: string): HTMLElement {
        return this._root.view.querySelectorAll(selector)[0] as HTMLElement;
    }

    protected getElements(selector: string): HTMLElement[] {
        return Array.from(this._root.view.querySelectorAll(selector)) as HTMLElement[];
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onStageResized() {
        if (this._latestWindowWidth != window.innerWidth) {
            this._latestWindowWidth = window.innerWidth;
            this.updateStyles();
        }

    }
}
