import '@webcomponents/custom-elements'
import '../styles/index.scss';
import {ErfurthPD} from "./com.dynafit.blacklight/ErfurthPD";

/* ----------------------------------------------------------------
 * Version
 * --------------------------------------------------------------*/

console.log("Erfurth Projektdesign " + process.env.version + (process.env.production ? "" : " (dev-build)"));

/* ----------------------------------------------------------------
 * Initialize the App
 * --------------------------------------------------------------*/

ErfurthPD.init();
