import {HeaderNavigationController} from "./header/HeaderNavigationController";

/******************************************************************
 * ControllerFactory
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ControllerFactory {

    private static CONTROLLER_DICTIONARY = [
        {selector: ".header", ControllerClass: HeaderNavigationController},
    ]

    static createComponentControllers() {
        ControllerFactory.CONTROLLER_DICTIONARY.forEach(entry => {
            Array.from(document.body.querySelectorAll(entry.selector)).forEach((element: HTMLElement) => {
                new entry.ControllerClass(element);
            });
        });
    }

}
