import {ControllerFactory} from "./controller/ControllerFactory";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {gsap} from "gsap";

/******************************************************************
 * ErfurthPD
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ErfurthPD {

    /******************************************************************
     * Properties
     *****************************************************************/

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    static init() {
        this.requireOGImage();
        this.initCore();
        this.initControllers();
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private static requireOGImage() {
        // og images need absolute path in meta tag og:image,
        // so it has to be required elsewhere (here ;) to be deployed to the dist folder
        require("CONTENT/og/epd-og.jpg")
    }

    private static initCore() {
        gsap.registerPlugin(ScrollToPlugin);
    }

    private static initControllers() {
        ControllerFactory.createComponentControllers();
    }

    /******************************************************************
     * Events
     *****************************************************************/

}
